var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-icon',{attrs:{"left":"","large":""}},[_vm._v("mdi-warehouse")]),_c('span',{staticClass:"headline"},[_vm._v("Ajustes de Inventario")]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"6","md":"6"}},[_c('v-select',{attrs:{"items":_vm.sucursales,"item-text":"nombre","item-value":"clavesucursal","menu-props":"auto","label":"Selecciona Sucursal","hide-details":"","prepend-icon":"mdi-office-building-marker-outline","single-line":"","clearable":""},on:{"change":_vm.getDataFromApi,"click:clear":_vm.clearFiltroSucursal},model:{value:(_vm.filtro_sucursal),callback:function ($$v) {_vm.filtro_sucursal=$$v},expression:"filtro_sucursal"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Escribe aquí una clave o producto (escribe mas de 3 letras)","hide-details":"","clearable":""},on:{"click:clear":_vm.clearFiltroCliente,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.grid_rows,"loading":_vm.isLoading,"options":_vm.options,"search":_vm.search,"server-items-length":_vm.totalgrid_rows,"items-per-page":5,"item-key":"id","no-data-text":"No se encontraron pedidos","loading-text":"Buscando pedidos","must-sort":true,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            'items-per-page-options': [10,25,50]
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editarItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmaBorrar(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1),_c('SnackbarMensajes',{attrs:{"sb":_vm.sb}}),_c('DialogEspera',{attrs:{"dialogEspera":_vm.dialogEspera}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }