<template>
  <v-card>
    <v-card-title primary-title>
      <v-icon left large>mdi-warehouse</v-icon>
      <span class="headline">Ajustes de Inventario</span>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-divider></v-divider>

    <v-card-text>
      <v-row>
        <v-col cols="6" md="6" class="pb-0 pt-0">
            <v-select
                  v-model="filtro_sucursal"
                  :items="sucursales"
                  item-text="nombre"
                  item-value="clavesucursal"
                  menu-props="auto"
                  label="Selecciona Sucursal"
                  hide-details
                  prepend-icon="mdi-office-building-marker-outline"
                  single-line
                  @change="getDataFromApi"
                  @click:clear="clearFiltroSucursal"
                  clearable
            ></v-select>
        </v-col>

        <v-col cols="6" md="6" class="pb-0 pt-0">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Escribe aquí una clave o producto (escribe mas de 3 letras)"
            hide-details
            clearable
            @click:clear="clearFiltroCliente"
            @keydown.enter="getDataFromApi"
          ></v-text-field>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="grid_rows"
            :loading="isLoading"
            :options.sync="options"
            :search="search"
            :server-items-length="totalgrid_rows"
            :items-per-page="5"
            item-key="id"
            no-data-text="No se encontraron pedidos"
            loading-text="Buscando pedidos"
            :must-sort="true"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-options': [10,25,50]
            }"
          >
            <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editarItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="confirmaBorrar(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
          </v-data-table> 
        </v-col>
      </v-row>
    </v-card-text>

    <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
    <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

  </v-card>
  

  
</template>

<script>
import moment from 'moment'
import ApiService from '@/services/ApiService.js'
import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
import DialogEspera from '@/components/DialogEspera.vue'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, numeric, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'GridPedidos',
  mixins: [validationMixin],
  components: { SnackbarMensajes, DialogEspera },
  data: () => ({
    sucursales: [],
    filtro_sucursal: '',
    filtro_pedido: '',
    filtro_vendedor: '',
    sb: {
      mostrar: false,
      mensaje: '',
      color: 'success',
      timeout: 3000,
      multiline: false,
    },
    menu_fecha_entrega: false,
    dialogEspera: false,
    dialogEditar: false,
    dialogEliminar: false,
    dialogEditarProducto: false,
    isLoading: true,
    grid_rows: [],
    totalgrid_rows: 0,
    editando: false,
    search: '',
    itemEliminar: {},
    editedItem: {
    },
    defaultItem: {
      id: -1,
      fecha: '',
      entrega_inmediata: true,
      fecha_entrega: '',
      cliente_nombre: '',
      cliente_apellidos: '',
      cliente_telefono: '',
      direccion_calle: '',
      direccion_numero: '',
      direccion_colonia: '',
      direccion_referencia: '',
      direccion_entrecalles: '',
      idvendedor: null,
      vendedor: {},
      vendedor_nombre: '',
      horaentrega_desde: '',
      horaentrega_hasta: '',
      comision_domicilio: '',
      total_pedido: '',
      observaciones: '',
      productos: [],
      productos_borrados: []
    },
    defaultProducto: {
      id: -1,
      idpedido: -1,
      descripcion: '',
      precio: 0,
      sucursal: '',
      entregado: false,
    },
    options: {
      sortBy: ['id'],
      sortDesc: [true]
    },
    headers: [
        { text: 'Id', value: 'id', sortable: true },
        { text: 'F. Captura', value: 'fecha', sortable: true},
        { text: 'Vendedor', value: 'vendedor_nombre', sortable: true},
        { text: 'Cliente', value: 'cliente_nombre', align: 'start', sortable: true },
        { text: 'Celular', value: 'cliente_telefono', sortable: false },
        { text: 'Solicitado para', value: 'fecha_entrega', sortable: false },
        { text: 'Opciones', value: 'actions', sortable: false },
    ],
    prods_headers: [
      { text: 'Producto', value: 'descripcion', sortable: false},
      { text: 'Precio', value: 'precio', sortable: false},
      { text: 'Opciones', value: 'actions', sortable: false },
    ],
    prods_options: {},
  }),
  computed: {
    cuandoRepartir(){
      return this.editedItem.entrega_inmediata ? 'Siguiente Entrega Inmediata' : 'Entrega Otro Día'
    },
    formTitle () {
      return this.editando ? `Editar pedido #${this.editedItem.id}` : 'Nuevo pedido'
    },
    fechahoraPedido () {
      return this.editando ? `${this.editedItem.created_at}` : ''
    },
    vendedorErrors () {
      const errors = []
      if (!this.$v.editedItem.vendedor_nombre.$dirty) return errors
      !this.$v.editedItem.vendedor_nombre.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.vendedor_nombre.required && errors.push('Es obligatorio el nombre de vendedor.')
      return errors
    },
    cliente_nombreErrors () {
      const errors = []
      if (!this.$v.editedItem.cliente_nombre.$dirty) return errors
      !this.$v.editedItem.cliente_nombre.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.cliente_nombre.required && errors.push('Obligatorio.')
      return errors
    },
    cliente_telefonoErrors () {
      const errors = []
      if (!this.$v.editedItem.cliente_telefono.$dirty) return errors
      !this.$v.editedItem.cliente_telefono.minLength && errors.push('Captura los 10 digitos del celular.')
      !this.$v.editedItem.cliente_telefono.maxLength && errors.push('Se permiten máximo 10 dígitos.')
      !this.$v.editedItem.cliente_telefono.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_calleErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_calle.$dirty) return errors
      !this.$v.editedItem.direccion_calle.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.direccion_calle.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_numeroErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_numero.$dirty) return errors
      !this.$v.editedItem.direccion_numero.maxLength && errors.push('Se permiten máximo 50 caracteres')
      !this.$v.editedItem.direccion_numero.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_coloniaErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_colonia.$dirty) return errors
      !this.$v.editedItem.direccion_colonia.maxLength && errors.push('Se permiten máximo 250 caracteres')
      !this.$v.editedItem.direccion_colonia.required && errors.push('Obligatorio.')
      return errors
    },
    productoPrecioErrors () {
      const errors = []
      if (!this.$v.defaultProducto.precio.$dirty) return errors
      !this.$v.defaultProducto.precio.numeric && errors.push('Precio inválido.')
      !this.$v.defaultProducto.precio.minValue && errors.push('Precio debe ser mayor a $10.')
      !this.$v.defaultProducto.precio.required && errors.push('Obligatorio.')
      return errors
    },
    productoDescripcionErrors () {
      const errors = []
      if (!this.$v.defaultProducto.descripcion.$dirty) return errors
      !this.$v.defaultProducto.descripcion.minLength && errors.push('Escribe la descripción completa incluyendo talla.')
      !this.$v.defaultProducto.descripcion.maxLength && errors.push('Se permiten máximo 250 caracteres.')
      !this.$v.defaultProducto.descripcion.required && errors.push('Obligatorio.')
      return errors
    },
    direccion_entrecallesErrors () {
      const errors = []
      if (!this.$v.editedItem.direccion_entrecalles.$dirty) return errors
      !this.$v.editedItem.direccion_entrecalles.maxLength && errors.push('Se permiten máximo 250 caracteres.')
      return errors
    },

  },
  validations: {
    editedItem: {
      vendedor_nombre: { required, maxLength: maxLength(250) },
      cliente_nombre: { required, maxLength: maxLength(250) },
      cliente_telefono: { required, minLength: minLength(10), maxLength: maxLength(14) },
      direccion_calle: { required, maxLength: maxLength(250) },
      direccion_numero: { required, maxLength: maxLength(50) },
      direccion_colonia: { required, maxLength: maxLength(250) },
      direccion_entrecalles: { maxLength: maxLength(250) },
      productos: { required, minLength: minLength(1) },
    },
    defaultProducto: {
      descripcion: { required, minLength: minLength(7), maxLength: maxLength(250) },
      precio: { required, numeric, minValue: minValue(10) },
    },
  },
  watch: {
    options: {
      handler(){
                        this.getDataFromApi();
      }
    },
    // search: {
    //   handler() {
    //     if(this.search.length > 2 || this.search.length == 0){
    //       this.getDataFromApi();
    //     }
    //   }
    // }
  },
  methods: {
    clearFiltroSucursal(){
        this.filtro_sucursal = "";
        this.getDataFromApi();
    },
    clearFiltroVendedor(event){
        this.filtro_vendedor = "";
        this.getDataFromApi();
    },
    clearFiltroCliente(event){
        this.search = "";
        this.getDataFromApi();
    },
    editarItem (item) {
      this.editando = true;
      this.editedItem.productos = []
      this.editedItem = Object.assign({}, item);
      this.editedItem.productos_borrados = []
      this.dialogEditar = true;
    },
    cerrarDialog () {
      this.dialogEditar = false
      this.getDataFromApi()
      
      this.editando = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },

    showMensaje(mensaje,color,multiline) {
      this.sb.mensaje = mensaje
      this.sb.multiline = multiline
      this.sb.color = color
      this.sb.mostrar = true
    },
    grabarProductos: function(idpedido) {
      
    },
    grabar: function(){
      
    },
    getCatalogos() {
      this.dialogEspera = true
      ApiService.getSucursales(this.$store.getters.token)
        .then(response => {
              if (response.status == 200) {
                  this.sucursales = response.data;
              } else {
                  this.showMensaje(
                      `Error al obtener sucursales ${response.status} - ${response.statusText} `,
                      'error', true)
              }
              this.dialogEspera = false;

          }).catch(error => {
              if (error.response) this.showMensaje(
                  `Error al obtener listado de sucursales ${error.response.status} - ${error.response.statusText}`,
                  'error', true)
              else this.showMensaje(`Error al obtener sucursales listado ${error.message}`, 'error', true)
              this.dialogEspera = false
            })
            .finally(() => (this.dialogEspera = false))
    },
    getDataFromApi() {
      this.isLoading = true
      if(!this.filtro_pedido) this.filtro_pedido = '';
      if(!this.search) this.search = '';
      if(!this.filtro_vendedor) this.filtro_vendedor = '';
      let filtros = {
        idpedido: this.filtro_pedido,
        cliente_nombre: this.search,
        vendedor_nombre: this.filtro_vendedor,
      }
      
      ApiService.getPedidos(this.$store.getters.token,this.options,filtros)
        .then(response => {
          if(response.status == 200){
            this.grid_rows = response.data;
            this.totalgrid_rows = parseInt(response.headers['x-pagination-total-count']);
            this.isLoading = false;
          }else{
            this.showMensaje(`Error al obtener listado ${response.status} - ${response.statusText} `,'error',true)
          }
        })
        .catch(error => {
          this.showMensaje(`Error al obtener listado ${error.response.status} - ${error.response.statusText}`,'error',true)
          this.isLoading = false
        })
        .finally(() => (this.isLoading = false))
    }
  },
  mounted() {
    this.getCatalogos();
    //this.getDataFromApi()
  },
  created() {
    this.editedItem = Object.assign({}, this.defaultItem);
  }
}
</script>
